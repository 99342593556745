import { cn } from '@virginexperiencedays/tailwind';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';

import { Image } from '../../layout/Image';
import { useBindings } from './EmblaBindings';
import { ControlButton, usePrevNextButtons } from './EmblaCarouselArrowButtons';
import { DotButton, useDotButton } from './EmblaCarouselDotButton';

import { useWindowWidth } from '@virginexperiencedays/hooks';
import type { EmblaOptionsType } from 'embla-carousel';
import type { HTMLAttributes } from 'react';
import {
  ImmersiveHeroImage,
  type ImmersiveHeroImageProps,
} from '../../pages/product/ImmersiveHeroImage';
import { useAutoplayControl } from './useAutoplayControl';

export type CarouselImage = {
  src: string;
  alt?: string;
  priority?: boolean;
};

// on swipe or nav click
export type CarouselCallbackProps = {
  currentIndex: number;
  selectedIndex: number;
  totalSlides: number;
  dir: 'next' | 'prev';
  gesture: 'swipe' | 'click';
};

export type Slide<T> = T & { immersive?: boolean };

export type EmblaCarouselProps = {
  slides: Slide<CarouselImage>[] | Slide<ImmersiveHeroImageProps>[];
  options?: Pick<EmblaOptionsType, 'loop' | 'active'>;
  priority?: boolean;
  imageTestId?: string;
  onSwipe?: (data: CarouselCallbackProps) => void;
  onNavClick?: (data: CarouselCallbackProps) => void;
  onSelectImage?: (index: number) => void;
  autoplay?: boolean;
  autoplayInterval?: number;
} & HTMLAttributes<HTMLElement>;

const AUTOPLAY_INTERVAL = 5000;

export const EmblaCarousel = ({ className, imageTestId, ...props }: EmblaCarouselProps) => {
  const { slides, options, autoplay, autoplayInterval } = props || {};

  if (slides.length === 0) return null;
  if (slides.length === 1 || !options?.active) {
    return slides[0].immersive ? (
      <ImmersiveHeroImage {...(slides[0] as ImmersiveHeroImageProps)} />
    ) : (
      <CarouselImageComponent {...slides[0]} className={className} imageTestId={imageTestId} />
    );
  }
  return (
    <Carousel
      {...props}
      className={className}
      autoplay={autoplay}
      autoplayInterval={autoplayInterval}
    />
  );
};

const CarouselImageComponent = ({
  src,
  alt,
  className,
  priority,
  imageTestId,
}: CarouselImage & { className?: string; imageTestId?: string }) => {
  return (
    <div
      className={cn(
        'pointer-events-none relative h-full w-full select-none',
        'after:absolute after:bottom-0 after:left-0 after:h-12 after:w-full after:bg-gradient-to-b after:from-transparent after:to-[rgba(0,0,0,0.25)]',
        className,
      )}
    >
      <Image
        src={src}
        alt={alt || ''}
        fill
        className="h-full w-full"
        priority={priority}
        dataTestId={imageTestId}
      />
    </div>
  );
};

const Carousel = ({
  slides,
  options,
  priority,
  className,
  onSwipe,
  onNavClick,
  onSelectImage,
  autoplayInterval,
  autoplay,
}: Omit<EmblaCarouselProps, 'disableScroll'>) => {
  const totalSlides = slides.length;

  const [emblaRef, emblaApi] = useEmblaCarousel({ ...options }, [
    Autoplay({
      playOnInit: autoplay,
      delay: autoplayInterval ?? AUTOPLAY_INTERVAL,
      stopOnMouseEnter: true,
    }),
  ]);
  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi, {
    onSelectImage,
  });

  const { isDesktop } = useWindowWidth();
  const isImmersiveImage = slides[0]?.immersive;

  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } =
    usePrevNextButtons(emblaApi, { onNavClick, totalSlides });

  useBindings({ onSwipe, totalSlides }, emblaApi);

  useAutoplayControl(emblaApi, autoplay, isDesktop());

  return (
    <div className={cn('embla relative [&:hover>div:nth-child(2)]:opacity-100', className)}>
      <div
        className="embla__viewport h-full w-full overflow-hidden"
        ref={emblaRef}
        aria-label="Image Carousel"
      >
        <div
          className="embla__container flex h-full w-full"
          style={{ backfaceVisibility: 'hidden', touchAction: 'pan-y pinch-zoom' }}
        >
          {slides.map((slide) => {
            if (slide.immersive) {
              const immersiveSlide = slide as Slide<ImmersiveHeroImageProps>;
              return (
                <div
                  className="embla__slide min-w-0 flex-shrink-0 flex-grow-0 basis-full"
                  key={immersiveSlide.imageMobile.url}
                >
                  <ImmersiveHeroImage {...immersiveSlide} />
                </div>
              );
            }

            const carouselSlide = slide as Slide<CarouselImage>;
            return (
              <div
                className="embla__slide min-w-0 flex-shrink-0 flex-grow-0 basis-full"
                key={`${slide.src}-${slide.alt}`}
              >
                <CarouselImageComponent
                  src={carouselSlide.src}
                  alt={carouselSlide.alt}
                  priority={priority}
                />
              </div>
            );
          })}
        </div>
      </div>

      <div
        className={cn(
          'embla__buttons pointer-events-none',
          'absolute inset-0 hidden h-full w-full items-center justify-between lg:flex',
          'coarse-hover:hidden opacity-0 transition-opacity duration-500 ease-in-out',
          isImmersiveImage && 'lg:max-w-[1440px] lg:mx-auto 2xl:max-w-[3840px]',
        )}
      >
        <ControlButton
          direction="prev"
          onClick={(e) => onPrevButtonClick(e, selectedIndex)}
          disabled={isImmersiveImage ? false : prevBtnDisabled}
          className={cn(
            'pointer-events-auto',
            isImmersiveImage && 'lg:pl-0 lg:pr-4  lg:ml-[40px] xl:ml-[80px]',
          )}
          size={isImmersiveImage && isDesktop() ? 'large' : 'default'}
          aria-label="previous slide"
        />
        <ControlButton
          direction="next"
          onClick={(e) => onNextButtonClick(e, selectedIndex)}
          disabled={isImmersiveImage ? false : nextBtnDisabled}
          className={cn(
            'pointer-events-auto',
            isImmersiveImage && 'lg:pl-4 lg:pr-0 lg:mr-[40px] xl:mr-[80px]',
          )}
          size={isImmersiveImage && isDesktop() ? 'large' : 'default'}
          aria-label="next slide"
        />
      </div>

      <div className="embla__dots pointer-events-auto absolute bottom-0 left-1/2 flex h-10 w-full -translate-x-1/2 select-none flex-nowrap items-center justify-center gap-2">
        {scrollSnaps.map((snap, index) => (
          <DotButton
            key={snap}
            onClick={() => onDotButtonClick(index)}
            className={cn(
              'embla__dot',
              'pointer-events-none z-10 flex h-2 w-2 touch-manipulation appearance-none items-center justify-center rounded-full bg-white opacity-50 transition-all duration-300',
              index === selectedIndex && 'opacity-100',
              isImmersiveImage && 'pointer-events-auto',
            )}
            style={{ transform: `scale(calc(1 - 0.${Math.abs(selectedIndex - index)}))` }}
            disabled={!isImmersiveImage}
            aria-label={`Slide ${index + 1}`}
            aria-current={index === selectedIndex ? 'true' : 'false'}
          />
        ))}
      </div>
    </div>
  );
};
