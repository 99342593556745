import { useCallback, useEffect, useMemo, useState } from 'react';

import type { EmblaCarouselType, EmblaEventType } from 'embla-carousel';
import type { CarouselCallbackProps, EmblaCarouselProps } from '.';

type CarouselEventTrackingProps = {
  lastGesture: 'swipe' | null;
  lastIndex: number;
  isScrolling: boolean;
};

type UseBindingProps = {
  // Callback triggered when a full swipe action completes and settles on a new slide
  onSwipe?: EmblaCarouselProps['onSwipe'];
  // Callback triggered immediately when user releases the pointer/touch during a swipe attempt
  onSwipeRelease?: EmblaCarouselProps['onSwipe'];
  totalSlides: number;
};

export type HandleSwipeEventProps = UseBindingProps & {
  emblaApi?: EmblaCarouselType;
  eventName: EmblaEventType;
  initialEvent: CarouselEventTrackingProps;
  event: CarouselEventTrackingProps;
  setEvent: (event: CarouselEventTrackingProps) => void;
};

export const useBindings = (
  { onSwipe, onSwipeRelease, totalSlides }: UseBindingProps,
  emblaApi?: EmblaCarouselType,
) => {
  const initialEvent = useMemo(() => {
    return {
      lastGesture: null,
      lastIndex: totalSlides,
      isScrolling: false,
    };
  }, [totalSlides]);

  const [event, setEvent] = useState<CarouselEventTrackingProps>(initialEvent);

  const bindSwipeEvent = useCallback(
    (emblaApi: EmblaCarouselType, eventName: EmblaEventType) => {
      if (!emblaApi) return;
      handleSwipeEvent({
        emblaApi,
        eventName,
        initialEvent,
        event,
        setEvent,
        onSwipe,
        onSwipeRelease,
        totalSlides,
      });
    },
    [event, totalSlides, initialEvent, onSwipe, onSwipeRelease],
  );

  useEffect(() => {
    if (!emblaApi) return;

    // slide in place, gets called on swipe, on nav click
    emblaApi.on('settle', bindSwipeEvent);
    // swipe start
    emblaApi.on('pointerDown', bindSwipeEvent);
    emblaApi.on('pointerUp', bindSwipeEvent);

    return () => {
      emblaApi.off('settle', bindSwipeEvent);
      emblaApi.off('pointerDown', bindSwipeEvent);
      emblaApi.off('pointerUp', bindSwipeEvent);
    };
  }, [emblaApi, bindSwipeEvent]);
};

export const handleSwipeEvent = ({
  emblaApi,
  eventName,
  initialEvent,
  event,
  setEvent,
  onSwipe,
  onSwipeRelease,
  totalSlides,
}: HandleSwipeEventProps) => {
  if (!emblaApi) return;
  const next = emblaApi.selectedScrollSnap();
  const lastSlide = totalSlides - 1;
  const dir =
    (event.lastIndex < next && !(event.lastIndex === 0 && next === lastSlide)) ||
    (event.lastIndex === lastSlide && next === 0)
      ? 'next'
      : 'prev';

  // since this event also gets called on nav click for desktop,
  // check last gesture to ensure it was a swipe to avoid duplicate tracking
  if (event.lastGesture === 'swipe') {
    const callbackData: Omit<CarouselCallbackProps, 'selectedIndex'> = {
      currentIndex: event.lastIndex,
      totalSlides,
      dir,
      gesture: 'swipe' as const,
    };

    switch (eventName) {
      case 'pointerUp': {
        // trigger callback (e.g. tracking) if user is able to attempt a swipe after releasing the pointer/touch
        onSwipeRelease?.({
          ...callbackData,
          selectedIndex: emblaApi.selectedScrollSnap(),
        });
        break;
      }

      case 'settle': {
        // trigger callback (e.g. tracking) only if user is able to do a full image swipe to the next/prev one
        if (event.lastIndex !== next) {
          onSwipe?.({
            ...callbackData,
            selectedIndex: next,
          });
        }

        // flag end of swipe
        setEvent(initialEvent);
        break;
      }

      default:
        break;
    }
  }

  if (event.isScrolling) return;

  if (eventName === 'pointerDown') {
    // flag start of swipe
    setEvent({
      lastIndex: emblaApi.selectedScrollSnap(),
      lastGesture: 'swipe',
      isScrolling: true,
    });
  }
};
