import { LinkWrap } from '@virginexperiencedays/components-v2/src/navigation/LinkWrap';
import { cn } from '@virginexperiencedays/tailwind';
import type { HTMLAttributes } from 'react';
import type { ImageObject } from '../../../utils/imageUtils';

import { Image } from '../../../layout/Image';

export type ImmersiveHeroImageProps = {
  /**
   * The image to display on mobile.
   */
  imageMobile: ImageObject;
  /**
   * The tablet image to display.
   */
  imageTablet: ImageObject;
  /**
   * The desktop image to display.
   */
  imageDesktop: ImageObject;
  /**
   * CTA link
   */
  ctaLink: string;
  /**
   * Is immersive
   */
  immersive: true;
} & HTMLAttributes<HTMLDivElement>;

export const ImmersiveHeroImage = ({
  imageMobile,
  imageTablet,
  imageDesktop,
  ctaLink,
  className,
  ...props
}: ImmersiveHeroImageProps) => {
  if (!imageMobile || !Object.keys(imageMobile).length) return null;

  return (
    <section className={cn('relative w-full', className)} data-testid="new-hero-image" {...props}>
      <h2 className="sr-only">
        A High-Resolution Image Representing the Product, Experience or Promotion
      </h2>
      <Image
        src={imageMobile.url ?? ''}
        imageObject={imageMobile}
        alt={imageMobile.alt ?? ''}
        height={imageMobile?.dimensions?.height ?? 940}
        width={imageMobile?.dimensions?.width ?? 780}
        className={cn('relative w-full', imageTablet?.url && 'md:hidden')}
        imageClassName="w-full"
        priority
      />
      {imageTablet?.url && (
        <Image
          src={imageTablet.url}
          imageObject={imageTablet}
          alt={imageTablet.alt ?? ''}
          height={imageTablet?.dimensions?.height ?? 508}
          width={imageTablet?.dimensions?.width ?? 744}
          className={cn('relative hidden w-full md:block', imageDesktop?.url && 'lg:hidden')}
          imageClassName="w-full"
          priority
        />
      )}
      {imageDesktop?.url && (
        <Image
          src={imageDesktop.url}
          imageObject={imageDesktop}
          alt={imageDesktop.alt ?? ''}
          height={imageDesktop?.dimensions?.height ?? 480}
          width={imageDesktop?.dimensions?.width ?? 1440}
          className={
            'relative hidden w-full cursor-pointer lg:flex items-center justify-center m-auto'
          }
          imageClassName=""
          priority
        />
      )}

      {ctaLink && (
        <LinkWrap
          href={ctaLink}
          className={cn(
            'absolute bottom-0 z-10 h-[calc(100%-130px)] w-full cursor-pointer focus:outline-none',
          )}
          aria-label="Shop now - visit the page with more information."
        >
          <span className="sr-only">Shop Now - visit the page with more information.</span>
        </LinkWrap>
      )}
    </section>
  );
};
