import type { EmblaCarouselType } from 'embla-carousel';
import type { AutoplayType } from 'embla-carousel-autoplay';
import { useEffect } from 'react';

export const useAutoplayControl = (
  emblaApi: EmblaCarouselType | undefined,
  autoplay: boolean | undefined,
  isDesktop: boolean,
) => {
  useEffect(() => {
    if (!autoplay || !emblaApi || typeof window === 'undefined') return;

    const autoplayInstance: AutoplayType | undefined = emblaApi.plugins()?.autoplay;
    if (!autoplayInstance) return;

    const carouselElement: HTMLElement | null = document.querySelector('.embla');

    if (!carouselElement) return;

    const buttons = carouselElement.querySelectorAll('.embla__buttons button, .embla__dots');

    // Common Handlers
    const stopAutoplay = () => autoplayInstance.stop();
    const resetAutoplay = () => autoplayInstance.reset();
    const resumeAutoplay = (event: MouseEvent | TouchEvent) => {
      if (!carouselElement.contains(event.target as Node)) {
        autoplayInstance.play();
      }
    };

    const onSwipeEnd = () => {
      autoplayInstance.reset();
      autoplayInstance.play();
    };

    if (!isDesktop) {
      emblaApi.on('pointerDown', resetAutoplay);
      emblaApi.on('pointerUp', onSwipeEnd);
      document.addEventListener('touchstart', resumeAutoplay);

      return () => {
        emblaApi.off('pointerDown', resetAutoplay);
        emblaApi.off('pointerUp', onSwipeEnd);
        document.removeEventListener('touchstart', resumeAutoplay);
      };
    }

    for (const button of buttons) {
      button.addEventListener('mouseenter', stopAutoplay);
      button.addEventListener('click', stopAutoplay);
    }

    carouselElement.addEventListener('mouseenter', stopAutoplay);
    carouselElement.addEventListener('mouseleave', autoplayInstance.play);
    document.addEventListener('mousedown', resumeAutoplay);

    return () => {
      for (const button of buttons) {
        button.removeEventListener('mouseenter', stopAutoplay);
        button.removeEventListener('click', stopAutoplay);
      }
      carouselElement.removeEventListener('mouseenter', stopAutoplay);
      carouselElement.removeEventListener('mouseleave', autoplayInstance.play);
      document.removeEventListener('mousedown', resumeAutoplay);
    };
  }, [emblaApi, autoplay, isDesktop]);
};
